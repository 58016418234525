import React from 'react';
import {
  Responsive, // eslint-disable-line
  List, SimpleList, Datagrid, FormDataConsumer, // eslint-disable-line
  Show, Edit, Create, Filter, // eslint-disable-line
  ShowButton, EditButton, CreateButton, SimpleForm, TabbedForm, TabbedShowLayout, Tab, FormTab, SaveButton, Toolbar, // eslint-disable-line
  TextInput, NumberInput, LongTextInput, BooleanInput, SelectInput, SelectArrayInput, ReferenceInput, ReferenceArrayInput, DisabledInput, ImageInput, ArrayInput, SimpleFormIterator, // eslint-disable-line
  TextField, NumberField, RichTextField, DateField, BooleanField, SelectField, SelectReferenceField, ReferenceField, ReferenceManyField, ReferenceArrayField, SingleFieldList, ChipField, ImageField, EmailField, PasswordField // eslint-disable-line
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import { withStyles } from '@material-ui/core/styles';

import CustomImageField from '../fields/CustomImageField';
import BreakLine from '../fields/BreakLine';
import { TIMES, QUANTITIES, LEVELS, BUDGETS } from '../constants/choices';

const RecipesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <BooleanInput label="Online" source="online" />
    <ReferenceInput label="Type" source="typeIds" reference="types">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Creator" source="createdBy" reference="users">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const styles = {
  button: { marginTop: '1em' },
  image: { position: 'absolute', top: 0, right: 0 },
};
const AddNewStepButton = withStyles(styles)(({ record, classes }) => (
  <CreateButton
    className={classes.button}
    to={{
      pathname: '/steps/create',
      search: `?recipeId=${record.id}`
    }}
  ></CreateButton>
));
export const RecipesList = (props) => (
  <List {...props} filters={<RecipesFilter />} bulkActions={false}>
    <Responsive
      small={
        <SimpleList
          primaryText={record => record.name}
          secondaryText={record => record.slug}
        />
      }
      medium={
        <Datagrid>
          <TextField label="Nom" source="name" />
          <BooleanField source="online" />
          <CustomImageField source="image.src" store="mini" style={{ maxWidth: '90px' }} />
          <ReferenceArrayField reference="types" source="typeIds" label="Types">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <ShowButton />
          <EditButton />
        </Datagrid>
      }
    />
  </List>
);

const RecipesTitle = ({ record }) => {
  return <span>Recipe {record ? `"${record.name}"` : ''}</span>;
};

const showStyles = {
  inline: { display: 'inline-block' },
  image: {
    // position: 'absolute',
    // top: '200px',
    // right: '24px',
  },
};

export const RecipesShow = withStyles(showStyles)((props) => (
  <Show name={<RecipesTitle />} {...props}>
    <TabbedShowLayout>

      <Tab label="Info">
        <TextField label="Nom" source="name" />
        <TextField label="Description" source="desc" />
        <RichTextField label="Présentation" source="content" />
      </Tab>

      <Tab label="Image">
        <CustomImageField source="image.src" store="mini" style={{ maxWidth: '90px' }} className={props.classes.image} />
      </Tab>

      <Tab label="Étapes" path="steps">

        <ReferenceManyField
          reference="steps"
          target="recipeId"
          addLabel={false}
          sort={{ field: 'order', order: 'ASC' }}
        >
          <Datagrid>
            <CustomImageField source="image.src" store="mini" />
            <TextField source="name" label="Nom" />
            <NumberField source="order" label="Ordre" />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
        <AddNewStepButton />

      </Tab>

      <Tab label="Commentaires" path="comments">

        <ReferenceManyField
          reference="comments"
          target="recipeId"
          addLabel={false}
          sort={{ field: 'createdAt', order: 'ASC' }}
        >
          <Datagrid>
            <ReferenceField label="Utilisateur" source="createdBy" reference="users" allowEmpty={true}>
              <TextField source="name" />
            </ReferenceField>
            <TextField source="text" label="Commentaire" />
            <DateField source="createdAt" showTime />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>

      </Tab>

      <Tab label="Settings" path="settings">

        <BooleanField source="online" className="inline" />

        <ReferenceArrayField label="Types" reference="types" source="typeIds">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>

        <NumberField label="Temps total" source="timeTotal" className="inline" />
        <SelectField label="Temps total par" source="timeTotalBy" choices={TIMES} className="inline" />

        <NumberField label="Temps de préparation" source="timeWork" className="inline" />
        <SelectField label="Temps de préparation par" source="timeWorkBy" choices={TIMES} className="inline" />

        <NumberField label="Temps de cuisson" source="timeCook" className="inline" />
        <SelectField label="Temps de cuisson par" source="timeCookBy" choices={TIMES} className="inline" />

        <NumberField label="Temps d'attente" source="timeWait" className="inline" />
        <SelectField label="Temps d'attente par" source="timeWaitBy" choices={TIMES} className="inline" />

      </Tab>

      <Tab label="Meta" path="meta">
        <ReferenceField source="createdBy" reference="users" allowEmpty={true}>
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" showTime />

        <ReferenceField source="updatedBy" reference="users" allowEmpty={true}>
          <TextField source="name" />
        </ReferenceField>
        <DateField source="updatedAt" showTime />
      </Tab>
    </TabbedShowLayout>
  </Show>
));

const editStyles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};

export const RecipesEdit = withStyles(editStyles)(({ classes, ...props }) => (
  <Edit name={<RecipesTitle />} {...props}>
    <TabbedForm>

      <FormTab label="Info">
        <TextInput source="name" label="Nom" />
        <DisabledInput source="slug" />
        <TextInput source="desc" label="Description" />
        <RichTextInput source="content" label="Présentation" />
      </FormTab>

      <FormTab label="Image" path="image">

        <ImageInput source="image" label="Image" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>

      </FormTab>

      <FormTab label="Settings" path="settings">

        <BooleanInput source="online" />

        <ReferenceArrayInput source="typeIds" reference="types" label="Types" sort={{ field: 'name', order: 'ASC' }} allowEmpty={true}>
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>

        <SelectInput label="Difficulté" source="level" choices={LEVELS} allowEmpty={true} />
        <SelectInput source="budget" choices={BUDGETS} allowEmpty={true} />

        <NumberInput label="Quantité" source="quantity" formClassName={classes.inlineBlock} />
        <FormDataConsumer formClassName={classes.inlineBlock} >
          {({ formData, ...rest }) => (formData.quantity > 0) &&
            <SelectInput label="Quantité par" source="quantityBy" choices={QUANTITIES} />
          }
        </FormDataConsumer>

        <BreakLine />

        <NumberInput label="Temps total" source="timeTotal" formClassName={classes.inlineBlock} />
        <FormDataConsumer formClassName={classes.inlineBlock} >
          {({ formData, ...rest }) => (formData.timeTotal > 0) &&
            <SelectInput label="Temps total par" source="timeTotalBy" choices={TIMES} />
          }
        </FormDataConsumer>

        <BreakLine />

        <NumberInput label="Temps de préparation" source="timeWork" formClassName={classes.inlineBlock} />
        <FormDataConsumer formClassName={classes.inlineBlock} >
          {({ formData, ...rest }) => (formData.timeWork > 0) &&
            <SelectInput label="Temps de préparation par" source="timeWorkBy" choices={TIMES} />
          }
        </FormDataConsumer>

        <BreakLine />

        <NumberInput label="Temps de cuisson" source="timeCook" formClassName={classes.inlineBlock} />
        <FormDataConsumer formClassName={classes.inlineBlock} >
          {({ formData, ...rest }) => (formData.timeCook > 0) &&
            <SelectInput label="Temps de cuisson par" source="timeCookBy" choices={TIMES} />
          }
        </FormDataConsumer>

        <BreakLine />

        <NumberInput label="Temps d'attente" source="timeWait" formClassName={classes.inlineBlock} />
        <FormDataConsumer formClassName={classes.inlineBlock} >
          {({ formData, ...rest }) => (formData.timeWait > 0) &&
            <SelectInput label="Temps d'attente par" source="timeWaitBy" choices={TIMES} />
          }
        </FormDataConsumer>

      </FormTab>

      <FormTab label="Ingredients" path="ingredients">
        <ArrayInput source="ingredients">
          <SimpleFormIterator>
            <TextInput label="Quantité" source="quantity" />
            <ReferenceInput label="Ingrédient" source="ingredientId" reference="ingredients" perPage={500} sort={{ field: 'name', order: 'ASC' }}>
              <SelectInput optionText="name" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="Materials" path="materials">
        <ArrayInput source="materials">
          <SimpleFormIterator>
            <TextInput label="Quantité" source="quantity" />
            <ReferenceInput label="Ustensile" source="materialId" reference="materials" perPage={500} sort={{ field: 'name', order: 'ASC' }}>
              <SelectInput optionText="name" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

    </TabbedForm>
  </Edit>
));

export const RecipesCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Nom" source="name" />
    </SimpleForm>
  </Create>
);