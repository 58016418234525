import React from 'react';
import PropTypes from 'prop-types';

import formatImage from '../utils/format-image';
import { get } from 'lodash';

const CustomImageField = ({ record = {}, source, store, style }) => {
    const value = get(record, source);
    return <img src={store ? formatImage(value, store) : value} alt="" style={style || {}} />
}
CustomImageField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    store: PropTypes.string.isRequired,
};

export default CustomImageField;