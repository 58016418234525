import React from 'react';
import { 
    Responsive, // eslint-disable-line
    List, SimpleList, Datagrid, // eslint-disable-line
    Show, Edit, Create, Filter, // eslint-disable-line
    ShowButton, EditButton, CreateButton, SimpleForm, TabbedForm, TabbedShowLayout, Tab, FormTab, SaveButton, Toolbar, // eslint-disable-line
    TextInput, NumberInput, LongTextInput, BooleanInput, SelectInput, SelectArrayInput, ReferenceInput, ReferenceArrayInput, DisabledInput, ImageInput, // eslint-disable-line
    TextField, NumberField, RichTextField, DateField, BooleanField, SelectField, SelectReferenceField, ReferenceField, ReferenceManyField, ReferenceArrayField, SingleFieldList, ChipField, ImageField, EmailField, PasswordField // eslint-disable-line
} from 'react-admin';
// import RichTextInput from 'ra-input-rich-text';

import { withStyles } from '@material-ui/core/styles';

import CustomImageField from '../fields/CustomImageField';

import * as qs from 'query-string';

const StepsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="name" alwaysOn />
        <BooleanInput label="Online" source="online" />
        <ReferenceInput label="Recipe" source="recipeId" reference="recipes">
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Creator" source="createdBy" reference="users">
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const StepsList = (props) => (
    <List {...props} filters={<StepsFilter />} bulkActions={false}>
        <Responsive
            small={
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.url}
                />
            }
            medium={
                <Datagrid>
                    <BooleanField source="online" />
                    <CustomImageField source="image.src" store="mini" style={{maxWidth: '90px'}} />
                    <TextField source="name" />
                    <NumberField source="order" />
                    <ReferenceField label="Recipe" source="recipeId" reference="recipes" sortBy="recipe.name" allowEmpty={true}>
                        <TextField source="name" />
                    </ReferenceField>
                    <ShowButton />
                    <EditButton />
                </Datagrid>
            }
        />
    </List>
);

const StepsTitle = ({ record }) => {
    return <span>Step {record ? `"${record.name}"` : ''}</span>;
};

const showStyles = {
    inline: { display: 'inline-block' },
    image: {
        /*position: 'absolute',
        top: '200px',
        right: '24px',*/
    },
};

export const StepsShow = withStyles(showStyles)((props) => (
    <Show name={<StepsTitle />} {...props}>
        <TabbedShowLayout>

            <Tab label="Info">
                <TextField source="name" />
                <TextField source="desc" />
            </Tab>

            <Tab label="Image">
                <CustomImageField source="image.src" store="mini" style={{maxWidth: '90px'}} />
            </Tab>

            <Tab label="Settings" path="settings">
                
                <BooleanField source="online" className="inline" />
                <NumberField source="order" className="inline" />

                <ReferenceField source="recipeId" reference="recipes" allowEmpty={true}>
                    <TextField source="name" />
                </ReferenceField>

            </Tab>

            <Tab label="Meta" path="meta">
                <ReferenceField source="createdBy" reference="users" allowEmpty={true}>
                    <TextField source="name" />
                </ReferenceField>
                <DateField source="createdAt" showTime />

                <ReferenceField source="updatedBy" reference="users" allowEmpty={true}>
                    <TextField source="name" />
                </ReferenceField>
                <DateField source="updatedAt" showTime />
            </Tab>
        </TabbedShowLayout>
    </Show>
));

const editStyles = {
    inline: { display: 'inline-block' },
};

export const StepsEdit = withStyles(editStyles)((props) => (
    <Edit name={<StepsTitle />} {...props}>
        <TabbedForm>
            <FormTab label="Info">

                <TextInput source="name" />
                <TextInput source="desc" />

            </FormTab>
            <FormTab label="Image">

                <ImageInput source="image" label="Image" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>

            </FormTab>
            <FormTab label="Settings">

                <BooleanInput source="online" />
                <NumberInput source="order" />
                <ReferenceInput source="recipeId" reference="recipes" label="Recipe" sort={{ field: 'name', order: 'ASC' }} allowEmpty={false}>
                    <SelectInput optionText="name" />
                </ReferenceInput>

            </FormTab>
        </TabbedForm>
    </Edit>
));

export const StepsCreate = (props) => {

    // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
    const { recipeId: recipeId_string } = qs.parse(props.location.search);
    const recipeId = recipeId_string || '';

    const redirect = recipeId ? `/recipes/${recipeId}/show/steps` : 'show';

    return (

        <Create {...props}>
            <SimpleForm defaultValue={{ recipeId }} redirect={redirect} >
                <ReferenceInput source="recipeId" reference="recipes" label="Recipe" sort={{ field: 'name', order: 'ASC' }}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="name" />
                <TextInput source="desc" />
                <ImageInput source="image" label="Image" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleForm>
        </Create>

    );
};


















