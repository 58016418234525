import React from 'react';
import { 
    Responsive, // eslint-disable-line
    List, SimpleList, Datagrid, // eslint-disable-line
    Show, Edit, Create, Filter, // eslint-disable-line
    ShowButton, EditButton, CreateButton, SimpleForm, TabbedForm, TabbedShowLayout, Tab, FormTab, SaveButton, Toolbar, // eslint-disable-line
    TextInput, NumberInput, LongTextInput, BooleanInput, SelectInput, SelectArrayInput, ReferenceInput, ReferenceArrayInput, DisabledInput, ImageInput, // eslint-disable-line
    TextField, NumberField, RichTextField, DateField, BooleanField, SelectField, SelectReferenceField, ReferenceField, ReferenceManyField, ReferenceArrayField, SingleFieldList, ChipField, ImageField, EmailField, PasswordField // eslint-disable-line
} from 'react-admin';
// import RichTextInput from 'ra-input-rich-text';

import { withStyles } from '@material-ui/core/styles';

import * as qs from 'query-string';

const CommentsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="name" alwaysOn />
        <BooleanInput label="Online" source="online" />
        <ReferenceInput label="Recipe" source="recipeId" reference="recipes">
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Creator" source="createdBy" reference="users">
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const CommentsList = (props) => (
    <List {...props} filters={<CommentsFilter />} bulkActions={false}>
        <Responsive
            small={
                <SimpleList
                    primaryText={record => record.user}
                />
            }
            medium={
                <Datagrid>
                    <BooleanField source="online" />
                    <ReferenceField label="User" source="createdBy" reference="users" allowEmpty={true}>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField label="Recipe" source="recipeId" reference="recipes" sortBy="recipe.name" allowEmpty={true}>
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="text" />
                    <DateField source="createdAt" showTime />
                    <ShowButton />
                    <EditButton />
                </Datagrid>
            }
        />
    </List>
);

const CommentsTitle = ({ record }) => {
    return <span>Comment</span>;
};

const showStyles = {
    inline: { display: 'inline-block' },
};

export const CommentsShow = withStyles(showStyles)((props) => (
    <Show name={<CommentsTitle />} {...props}>
        <TabbedShowLayout>

            <Tab label="Info">
                <TextField source="user" />
                <TextField source="text" />
            </Tab>

            <Tab label="Settings" path="settings">
                
                <BooleanField source="online" className="inline" />

                <ReferenceField source="recipeId" reference="recipes" allowEmpty={true}>
                    <TextField source="name" />
                </ReferenceField>

            </Tab>

            <Tab label="Meta" path="meta">
                <ReferenceField source="createdBy" reference="users" allowEmpty={true}>
                    <TextField source="name" />
                </ReferenceField>
                <DateField source="createdAt" showTime />

                <ReferenceField source="updatedBy" reference="users" allowEmpty={true}>
                    <TextField source="name" />
                </ReferenceField>
                <DateField source="updatedAt" showTime />
            </Tab>
        </TabbedShowLayout>
    </Show>
));

const editStyles = {
    inline: { display: 'inline-block' },
};

export const CommentsEdit = withStyles(editStyles)((props) => (
    <Edit name={<CommentsTitle />} {...props}>
        <TabbedForm>
            <FormTab label="Info">

                <DisabledInput source="user" />
                <TextInput source="text" />

            </FormTab>
            <FormTab label="Settings">

                <BooleanInput source="online" />
                <ReferenceInput source="recipeId" reference="recipes" label="Recipe" sort={{ field: 'name', order: 'ASC' }} allowEmpty={false}>
                    <SelectInput optionText="name" />
                </ReferenceInput>

            </FormTab>
        </TabbedForm>
    </Edit>
));

export const CommentsCreate = (props) => {

    // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
    const { recipeId: recipeId_string } = qs.parse(props.location.search);
    const recipeId = recipeId_string || '';

    const redirect = recipeId ? `/recipes/${recipeId}/show/comments` : 'show';

    return (

        <Create {...props}>
            <SimpleForm defaultValue={{ recipeId }} redirect={redirect} >
                <ReferenceInput source="recipeId" reference="recipes" label="Recipe" sort={{ field: 'name', order: 'ASC' }}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="text" />
            </SimpleForm>
        </Create>

    );
};


















