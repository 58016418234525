import React from 'react';
import { List, Datagrid, EmailField, TextField, DateField } from 'react-admin';

export const MessagesList = (props) => (
    <List title="Received messages" {...props} bulkActions={false}>
        <Datagrid>
            {/*<TextField source="id" />*/}
            <TextField source="name" />
            <EmailField source="email" />
            <TextField source="phone" />
            <TextField source="subject" />
            <TextField source="body" />
            <DateField source="createdAt" />
        </Datagrid>
    </List>
);