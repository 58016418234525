// in src/App.js

import React from 'react';
import { Admin, Resource } from 'react-admin';

import { authClient, restClient } from 'ra-data-feathers';
import feathersClient from './feathersClient';

// import Dashboard from './resources/dashboard';

import { PagesList, PagesEdit, PagesCreate } from './resources/pages';
import PagesIcon from '@material-ui/icons/Book';

import { RecipesList, RecipesShow, RecipesEdit, RecipesCreate } from './resources/recipes';
import RecipesIcon from '@material-ui/icons/Grade';

import { StepsList, StepsShow, StepsEdit, StepsCreate } from './resources/steps';
import StepsIcon from '@material-ui/icons/FormatListNumbered';

import { TypesList, TypesEdit, TypesCreate } from './resources/types';
import TypesIcon from '@material-ui/icons/Bookmark';

import { IngredientsList, IngredientsEdit, IngredientsCreate } from './resources/ingredients';
import IngredientsIcon from '@material-ui/icons/ShoppingCart';

import { MaterialsList, MaterialsEdit, MaterialsCreate } from './resources/materials';
import MaterialsIcon from '@material-ui/icons/Build';

import { CommentsList, CommentsShow, CommentsEdit, CommentsCreate } from './resources/comments';
import CommentsIcon from '@material-ui/icons/QuestionAnswer';

import { UsersList, UsersEdit, UsersCreate } from './resources/users';
import UsersIcon from '@material-ui/icons/Group';

import { MessagesList } from './resources/messages';
import MessagesIcon from '@material-ui/icons/Email';

import addUploadFeature from './addUploadFeature';

import { includes } from 'lodash';

const authClientOptions = {
  storageKey: 'feathers-jwt',
  authenticate: { strategy: 'local' }
};

// const restClientOptions = {};
// to rename id field for *all* resources use this syntax:
const restClientOptions = { id: '_id' };
// to rename id field(s) for specific resources use this syntax:
// const restClientOptions = {'my-resource': {id: '_id'}};
// Use HTTP PATCH method instead of PUT to implement UPDATE
// const restClientOptions = { usePatch: true };

const dataProvider = restClient(feathersClient, restClientOptions);
const uploadCapableDataProvider = addUploadFeature(dataProvider);

const App = () => (
  <Admin 
    title="Au bon goût des Rao"
    // dashboard={Dashboard}
    authProvider={authClient(feathersClient, authClientOptions)}
    dataProvider={uploadCapableDataProvider}
  >
    {permissions => [
      includes(['admin', 'edit'], permissions) ? <Resource
        name="recipes"
        options={{ label: 'Recettes' }}
        icon={RecipesIcon}
        list={RecipesList}
        create={includes(['admin', 'edit'], permissions) ? RecipesCreate : null}
        show={includes(['admin', 'edit'], permissions) ? RecipesShow : undefined}
        edit={includes(['admin', 'edit'], permissions) ? RecipesEdit : undefined}
      /> : null,
      includes(['admin', 'edit'], permissions) ? <Resource
        name="steps"
        options={{ label: 'Étapes' }}
        icon={StepsIcon}
        list={StepsList}
        create={includes(['admin', 'edit'], permissions) ? StepsCreate : null}
        show={includes(['admin', 'edit'], permissions) ? StepsShow : undefined}
        edit={includes(['admin', 'edit'], permissions) ? StepsEdit : undefined}
      /> : null,
      includes(['admin', 'edit'], permissions) ? <Resource
        name="types"
        icon={TypesIcon}
        list={TypesList}
        create={includes(['admin', 'edit'], permissions) ? TypesCreate : null}
        edit={includes(['admin', 'edit'], permissions) ? TypesEdit : undefined}
      /> : null,
      includes(['admin', 'edit'], permissions) ? <Resource
        name="ingredients"
        icon={IngredientsIcon}
        list={IngredientsList}
        create={includes(['admin', 'edit'], permissions) ? IngredientsCreate : null}
        edit={includes(['admin', 'edit'], permissions) ? IngredientsEdit : undefined}
      /> : null,
      includes(['admin', 'edit'], permissions) ? <Resource
        name="materials"
        options={{ label: 'Ustensiles' }}
        icon={MaterialsIcon}
        list={MaterialsList}
        create={includes(['admin', 'edit'], permissions) ? MaterialsCreate : null}
        edit={includes(['admin', 'edit'], permissions) ? MaterialsEdit : undefined}
      /> : null,
      includes(['admin', 'edit'], permissions) ? <Resource
        name="comments"
        options={{ label: 'Commentaires' }}
        icon={CommentsIcon}
        list={CommentsList}
        create={includes(['admin', 'edit'], permissions) ? CommentsCreate : null}
        show={includes(['admin', 'edit'], permissions) ? CommentsShow : undefined}
        edit={includes(['admin', 'edit'], permissions) ? CommentsEdit : undefined}
      /> : null,
      includes(['admin', 'edit'], permissions) ? <Resource
        name="pages"
        icon={PagesIcon}
        list={PagesList}
        create={includes(['admin'], permissions) ? PagesCreate : null}
        edit={includes(['admin', 'edit'], permissions) ? PagesEdit : undefined}
      /> : null,
      includes(['admin'], permissions) ? <Resource 
        name="messages" 
        icon={MessagesIcon} 
        list={MessagesList} 
      /> : null,
      includes(['admin'], permissions) ? <Resource 
        name="users" 
        options={{ label: 'Utilisateurs' }}
        icon={UsersIcon} 
        list={UsersList} 
        create={includes(['admin'], permissions) ? UsersCreate : null}
        edit={includes(['admin'], permissions) ? UsersEdit : undefined}
      /> : null
    ]}
  </Admin>
);

export default App;