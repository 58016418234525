// in src/feathersClient.js
import feathers from "@feathersjs/client";
import auth from '@feathersjs/client/authentication';

const host = window.SETTINGS.apiUrl;

const authOptions = { jwtStrategy: 'jwt', storage: window.localStorage };

export default feathers()
    .configure(feathers.rest(host).fetch(window.fetch.bind(window)))
    .configure(auth(authOptions));