import React from 'react';
import PropTypes from 'prop-types';

const IconField = ({ record = {}, source }) => {
    if (!record[source]) return '';
    return <i className={record[source]} ></i>
}
IconField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default IconField;