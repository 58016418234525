import React from 'react';
import { 
    Responsive, // eslint-disable-line
    List, SimpleList, Datagrid, // eslint-disable-line
    Edit, Create, Filter, // eslint-disable-line
    EditButton, SimpleForm, TabbedForm, FormTab, // eslint-disable-line
    NumberInput, TextInput, LongTextInput, BooleanInput, SelectInput, SelectArrayInput, ReferenceInput, ReferenceArrayInput, DisabledInput, ImageInput, // eslint-disable-line
    NumberField, TextField, DateField, BooleanField, ReferenceField, ReferenceArrayField, SingleFieldList, ChipField, ImageField, EmailField, PasswordField // eslint-disable-line
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import CustomImageField from '../fields/CustomImageField';

const MaterialsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="name" alwaysOn />
        <BooleanInput label="Online" source="online" />
        <ReferenceInput label="Creator" source="createdBy" reference="users">
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const MaterialsList = (props) => (
    <List {...props} filters={<MaterialsFilter />} bulkActions={false}>
        <Responsive
            small={
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.slug}
                />
            }
            medium={
                <Datagrid>
                    <BooleanField source="online" />
                    <TextField source="name" />
                    <CustomImageField source="image.src" store="mini" style={{maxWidth: '90px'}} />
                    <EditButton />
                </Datagrid>
            }
        />
    </List>
);

const MaterialsTitle = ({ record }) => {
    return <span>Material {record ? `"${record.name}"` : ''}</span>;
};

export const MaterialsEdit = (props) => (
    <Edit name={<MaterialsTitle />} {...props}>
        <TabbedForm>
            <FormTab label="Info">
                <TextInput source="name" />
                <TextInput source="desc" />
                <RichTextInput source="content" />
            </FormTab>
            <FormTab label="Image">
                <ImageInput source="image" label="Image" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>
            </FormTab>
            <FormTab label="Settings">
                <BooleanInput source="online" />
            </FormTab>
            <FormTab label="Meta">

                <ReferenceField source="createdBy" reference="users" allowEmpty={true}>
                    <TextField source="name" />
                </ReferenceField>
                <DateField source="createdAt" showTime />

                <ReferenceField source="updatedBy" reference="users" allowEmpty={true}>
                    <TextField source="name" />
                </ReferenceField>
                <DateField source="updatedAt" showTime />

            </FormTab>
        </TabbedForm>
    </Edit>
);

export const MaterialsCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
        </SimpleForm>
    </Create>
);