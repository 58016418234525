import React from 'react';

import { 
    Responsive, // eslint-disable-line
    List, SimpleList, Datagrid, // eslint-disable-line
    Edit, Create, Filter, // eslint-disable-line
    EditButton, SimpleForm, // eslint-disable-line
    TextInput, LongTextInput, BooleanInput, SelectInput, SelectArrayInput, ReferenceInput, ReferenceArrayInput, DisabledInput, ImageInput, // eslint-disable-line
    TextField, DateField, BooleanField, ReferenceArrayField, SingleFieldList, ChipField, ImageField, EmailField, PasswordField // eslint-disable-line
} from 'react-admin';

const UsersFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        {/*<ReferenceInput label="User" source="createdBy" reference="users" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput>*/}
    </Filter>
);

export const UsersList = (props) => (
    <List title="All users" {...props} filters={<UsersFilter />} bulkActions={false}>
        <Datagrid>
            {/*<TextField source="id" />*/}
            
            {/*<TextField source="verifyToken" />
            <TextField source="verifyShortToken" />
            <DateField source="verifyExpires" />*/}

            <TextField source="name" />
            <EmailField source="email" />
            <BooleanField source="isVerified" />
            <TextField source="roles" />
            <DateField source="createdAt" />
            <EditButton />
        </Datagrid>
    </List>
);


const UsersTitle = ({ record }) => {
    return <span>User {record ? `"${record.name}"` : ''}</span>;
};

export const UsersEdit = (props) => (
    <Edit name={<UsersTitle />} {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <DisabledInput source="slug" />
            <DisabledInput source="email" />
            <DisabledInput source="phone" />
            <BooleanInput source="isVerified" />
            <SelectInput source="roles" allowEmpty choices={[
                { id: 'admin', name: 'Adminitrator' },
                { id: 'edit', name: 'Editor' }
            ]} />
            <DateField source="createdAt" />
        </SimpleForm>
    </Edit>
);

export const UsersCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="email" type="email" />
            <TextInput source="password" type="password" />
        </SimpleForm>
    </Create>
);
