// import entire SDK
import AWS from 'aws-sdk';
// import AWS object without services
// import AWS = require('aws-sdk/global');
// import individual service
// import S3 = require('aws-sdk/clients/s3');

var bucketName = window.SETTINGS.AwsBucketName;
var bucketRegion = window.SETTINGS.AwsBucketRegion;
var IdentityPoolId = window.SETTINGS.AwsIdentityPoolId;

AWS.config.update({
  region: bucketRegion,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IdentityPoolId
  })
});

var s3 = new AWS.S3({
  apiVersion: '2006-03-01',
  params: {Bucket: bucketName}
});

export default s3;