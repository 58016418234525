// in addUploadFeature.js
import { kebabCase } from 'lodash';
import moment from 'moment';
import s3 from './aws';
/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
/*const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});
*/
const uploadFileToS3 = (image, model, type='image') => new Promise((resolve, reject) => {

    var file = image.rawFile;

    var fileNameHash = (image.title || '').toLowerCase().split('.');
    var name = fileNameHash.slice(0, -1).join('.');
    var ext = fileNameHash[fileNameHash.length-1];

    var fileName = moment().format('YYYY-MM-DD-HH-mm-ssss') + '-' + kebabCase(name) + '.' + ext;
    var folderName = `${model}/${type}/original/`;
    var key = folderName + fileName;

    s3.upload({
        Key: key,
        Body: file,
        ACL: 'public-read'
    }, function(err, data) {
        if (err) return reject(err);
        resolve(data);
    });

});

/**
 * For recipes update only, convert uploaded image in base 64 and attach it to
 * the `image` sent property, with `src` and `title` attributes.
 */
const addUploadFeature = requestHandler => (type, resource, params) => {
    if (type === 'UPDATE' && resource === 'recipes') {
        
        if (params.data.image && params.data.image.rawFile && params.data.image.rawFile instanceof File) {

            return uploadFileToS3(params.data.image, 'recipes', 'image')
                .then(res => ({
                    src: res.Location,
                    title: `${params.data.image.title}`
                }))
                .then(imageData => requestHandler(type, resource, {
                    ...params,
                    data: {
                        ...params.data,
                        image: imageData,
                    },
                }));

        }
    }
    if ((type === 'UPDATE' || type === 'CREATE') && resource === 'steps') {
        
        if (params.data.image && params.data.image.rawFile && params.data.image.rawFile instanceof File) {

            return uploadFileToS3(params.data.image, 'steps', 'image')
                .then(res => ({
                    src: res.Location,
                    title: `${params.data.image.title}`
                }))
                .then(imageData => requestHandler(type, resource, {
                    ...params,
                    data: {
                        ...params.data,
                        image: imageData,
                    },
                }));

        }
    }
    if ((type === 'UPDATE' || type === 'CREATE') && resource === 'ingredients') {
        
        if (params.data.image && params.data.image.rawFile && params.data.image.rawFile instanceof File) {

            return uploadFileToS3(params.data.image, 'ingredients', 'image')
                .then(res => ({
                    src: res.Location,
                    title: `${params.data.image.title}`
                }))
                .then(imageData => requestHandler(type, resource, {
                    ...params,
                    data: {
                        ...params.data,
                        image: imageData,
                    },
                }));

        }
    }
    if ((type === 'UPDATE' || type === 'CREATE') && resource === 'materials') {
        
        if (params.data.image && params.data.image.rawFile && params.data.image.rawFile instanceof File) {

            return uploadFileToS3(params.data.image, 'materials', 'image')
                .then(res => ({
                    src: res.Location,
                    title: `${params.data.image.title}`
                }))
                .then(imageData => requestHandler(type, resource, {
                    ...params,
                    data: {
                        ...params.data,
                        image: imageData,
                    },
                }));

        }
    }
    // for other request types and reources, fall back to the defautl request handler
    return requestHandler(type, resource, params);
};

export default addUploadFeature;