export const TIMES = [
  { id: 'second', name: 'Secondes' },
  { id: 'minute', name: 'Minutes' },
  { id: 'hour', name: 'Heures' },
  { id: 'day', name: 'Jours' },
  { id: 'week', name: 'Semaines' },
  { id: 'month', name: 'Mois' },
  { id: 'year', name: 'Années' }
];

export const QUANTITIES = [
  { id: 'person', name: 'Personnes' },
  { id: 'g', name: 'Grammes' },
  { id: 'kg', name: 'Kilogrammes' },
  { id: 'piece', name: 'Pièces' },
];

export const LEVELS = [
  { id: 0, name: 'Facile' },
  { id: 1, name: 'Normal' },
  { id: 2, name: 'Difficile' },
];

export const BUDGETS = [
  { id: 0, name: 'Economique' },
  { id: 1, name: 'Modéré' },
  { id: 2, name: 'Onereux' },
];
